import moment from 'moment-timezone'
import { Moment } from 'moment-timezone'

export type Template = {
    templateId: string
    practiceId?: string
    name: string
    templateType: TemplateType
    template: string
    created: Moment
    updated: Moment
}

export enum TemplateType {
    VWR = 'VWR',
}
