import React from 'react'
import { useAtom } from 'jotai'

import {
    useAdminPracticeBase,
} from '@docpace/shared-react-hooks/useAdminPracticeBase'
import {
    usePracticeTemplatesTranslations,
} from '@docpace/shared-react-hooks/usePracticeTemplatesTranslations'
import {
    useProviderDepartmentOptions,
} from '@docpace/shared-react-hooks/useProviderDepartmentOptions'
import {
    ctxPdoItem,
    ctxSelectedPdoIds,
} from '@docpace/shared-react-atoms'
import {
    ProviderDepartmentOptionDataType,
} from '@docpace/shared-ts-types/base/ProviderDepartmentOption'

import { AdminUpdateItemTranslationForm } from '@docpace/admin-react-forms/AdminUpdateItemTranslationForm'
import { AdminUpdateItemTristateForm } from '@docpace/admin-react-forms/AdminUpdateItemTristateForm'
import { AdminUpdateItemPracticeSlugForm } from '@docpace/admin-react-forms/AdminUpdateItemPracticeSlugForm'
import { AdminUpdateItemIntNumberForm } from '@docpace/admin-react-forms/AdminUpdateItemIntNumberForm'
import { AdminUpdateItemStatsTypeForm } from '@docpace/admin-react-forms/AdminUpdateItemStatsTypeForm'
import { AdminSearchGooglePlaceForm } from '@docpace/admin-react-forms/AdminSearchGooglePlaceForm'
import { useSnackbar } from 'notistack'
import { PdoConfigItem } from '@docpace/admin-react-components'
import { makeAdminProviderDepartmentOptionRows } from '../AdminProviderDepartmentOptionRows'

interface AdminProviderDepartmentOptionFormProps {}

export const AdminProviderDepartmentOptionForm = (
    props: AdminProviderDepartmentOptionFormProps
) => {
    const { enqueueSnackbar } = useSnackbar()
    const { practice, isLoading, providers } = useAdminPracticeBase()
    const { translations } = usePracticeTemplatesTranslations()
    const [ configKey ] = useAtom(ctxPdoItem)
    const [[ configIds, ...restIds ], setSelectedPdoIds] = useAtom(ctxSelectedPdoIds)
    const {
        getProviderDepartmentOptionByIds,
        updateProviderDepartmentOption,
        createProviderDepartmentOption,
        refetchProviderDepartmentOptions,
    } = useProviderDepartmentOptions()

    const configSelectedPdo = getProviderDepartmentOptionByIds({ ...configIds })
    
    const pdoConfigItems: PdoConfigItem[] = makeAdminProviderDepartmentOptionRows({})
    const pdoConfigItem = pdoConfigItems?.find((r)=>{ return r.name === configKey })
    const [ __configItem, setPdoItem ] = useAtom(ctxPdoItem)
    async function handlePatchSubmit(formValues) {
        const patchProviderDepartmentOption = configSelectedPdo
        if (formValues?.[configKey] === '') {
            formValues[configKey] = null
        }
        const patch = {
            ...formValues,
            ...configIds,
        }
        try {
            if (patchProviderDepartmentOption) {
                await updateProviderDepartmentOption({
                    variables: { input: { 
                        patch, 
                        providerDepartmentOptionId: patchProviderDepartmentOption.providerDepartmentOptionId 
                    } },
                })
            } else {
                await createProviderDepartmentOption({
                    variables: {
                        input: {
                            providerDepartmentOption: { 
                                ...patch, 
                                practiceId: configIds?.practiceId 
                            },
                        },
                    },
                })
            }
            await refetchProviderDepartmentOptions()
            enqueueSnackbar('Success', { variant: 'success' })
            setSelectedPdoIds([])
            setPdoItem(null)
        } catch (e) {
            enqueueSnackbar(
                'there was a problem - check the console for errors',
                {
                    variant: 'error',
                }
            )
            console.log(e)
        }
    }

    const dialogFormDefaultValues = {
        title: configKey,
        name: configKey,
        defaultValues: {
            [configKey]:
                configSelectedPdo?.[configKey],
        },
        onCancel: () => {
            setPdoItem(null)
            setSelectedPdoIds([])
        },
        onSubmit: handlePatchSubmit,
    }

    return (<div className="p-2 bg-white w-full" style={{ maxWidth: 600 }}>
        <div 
            className="border-red-200 border-solid bg-gray-200 rounded text-sm mb-2 whitespace-normal w-full" 
        >
            {JSON.stringify(configIds ?? {})}
        </div>
        {pdoConfigItem?.type ===
            ProviderDepartmentOptionDataType.googlePlace && (
            <AdminSearchGooglePlaceForm
                {...dialogFormDefaultValues}
                onSubmit={async (googlePlaceId) => {
                    return handlePatchSubmit({ googlePlaceId })
                }}
            />
        )}
        {pdoConfigItem?.type ===
            ProviderDepartmentOptionDataType.translation && (
            <AdminUpdateItemTranslationForm
                {...dialogFormDefaultValues}
                translationType={pdoConfigItem?.translationType}
                translations={translations}
            />
        )}
        {pdoConfigItem?.type ===
            ProviderDepartmentOptionDataType.checkbox && (
            <AdminUpdateItemTristateForm {...dialogFormDefaultValues}/>
        )}
        {pdoConfigItem?.type ===
            ProviderDepartmentOptionDataType.intNumber && (
            <AdminUpdateItemIntNumberForm {...dialogFormDefaultValues}/>
        )}
        {pdoConfigItem?.type ===
            ProviderDepartmentOptionDataType.practiceSlug && (
            <AdminUpdateItemPracticeSlugForm
                {...dialogFormDefaultValues}
                practiceSlugs={practice?.practiceSlugs?.nodes}
            />
        )}
        {pdoConfigItem?.type ===
            ProviderDepartmentOptionDataType.statsType && (
            <AdminUpdateItemStatsTypeForm {...dialogFormDefaultValues} />
        )}
    </div>)
}
