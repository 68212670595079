import { Moment, Duration } from 'moment-timezone'
import moment from 'moment-timezone'
import {
    filter,
    includes,
    toNumber,
    reduce,
    sortBy,
    reverse,
    orderBy,
} from 'lodash'
import { first, compact, last } from 'lodash'
import {
    AppointmentStatus,
    CalculatedAppointmentStatus,
} from '@docpace/shared-ts-types/base/AppointmentStatus'
import {
    TranslationContentFragment,
    AppointmentDetailFragment,
    AppointmentOverviewFragment,
    AppointmentTokenSessionFragment,
    AppointmentEstimateFragment,
    ProviderDepartmentOptionCompiledFragment,
    StatsCompiledFragment,
    MlPredictedDurationsFragment,
    AppointmentCycleConfigCompiledFragment,
    AppointmentTextFragment,
} from '@docpace/shared-graphql/fragments'

import {
    NotStartedAppointmentStatuses,
    compareAppointmentStatus,
} from '../../src/base/AppointmentStatus'

import {
    appointmentToCalculatedAppointmentStatusIndex,
    isAppointmentInCompletedState,
} from '@docpace/shared-ts-utils/appointmentStatusUtils'

import {
    EHRSystem,
} from '../../src/base/EHRSystem'

import { getEarliestTimestampInSet, getLatestTimestampInSet } from '../utils/DatetimeUtils'
import { 
    getEarliestAppointmentEndTimeKey, 
    getFirstAppointmentStartTimeKey,
    makeDurationFromStartEndTimes
} from '@docpace/shared-ts-utils/appointmentStartEndUtils'

// export type AppointmentTsKey =
//     | 'timeCheckOutStart'
//     | 'timeCheckOutEnd'
//     | 'timeEncounterCheckedOut'
//     | 'timeCheckInStart'
//     | 'timeCheckIn'
//     | 'timeCheckInEnd'
//     | 'timeIntakeStart'
//     | 'timeIntakeEndDerived'
// export const appointmentStartTimeKeys = [
//     'timeCheckInStart',
//     'timeCheckIn',
//     'timeCheckInEnd',
//     'timeIntakeStart',
//     'timeIntakeEndDerived',
// ]
export const appointmentEndTimeKeys = [
    '_timeCheckOutStart',
    '_timeCheckOutEnd',
    '_timeEncounterCheckedOut',
]

export type DelayTimestampsAppointment = { 
    appointmentId: string
    providerId?: string
    practiceId?: string
    appointmentTypeId?: string
    patientId?: string
    urgent?: boolean
    appointmentEstimate?: AppointmentEstimateFragment | null
    providerDepartmentOptionCompiled?: ProviderDepartmentOptionCompiledFragment | null
    srcType: string | EHRSystem
    statsCompiled?: StatsCompiledFragment | null
    appointmentPredictedDuration?: MlPredictedDurationsFragment | null
    appointmentCycleConfigCompiled?: AppointmentCycleConfigCompiledFragment | null
    appointmentTexts: { nodes: (AppointmentTextFragment | null)[] } | null
    timeScheduled: string,
    timeCreated?: string | null
    timeUpdated?: string | null
    created?: string | null
    updated?: string | null
    pausedAt?: string | null
    status: string | AppointmentStatus
    calculatedAppointmentStatus?: CalculatedAppointmentStatus
    _timeCheckInStart?: string | null
    _timeCheckInEnd?: string | null
    _timeIntakeStart?: string | null
    _timeIntakeEnd?: string | null
    _timeExamStart?: string | null
    _timeExamEnd?: string | null
    _timeCheckOutStart?: string | null
    _timeCheckOutEnd?: string | null
    timeEncounterCheckedOut?: string | null
    patientManualCheckIn?: string | null
    rescheduledAppointmentId?: string | null
}

// type AppointmentTsKeyVal = {
//     key: AppointmentTsKey
//     value: string | null
// }

export type SsrAppointment = {
    appointmentId?: string
    practiceId?: string
    athenaPracticeId?: string
    patientManualCheckIn?: string
    departmentId?: string
    providerId?: string
    timeScheduled?: string
    suggestedArrivalTime?: string
    providerDisplayName?: string
    languages?: string[]
    practiceName?: string
    srcType?: string
    status?: string
    timePatientSiteFirstVisited?: string
    timePatientSiteIntakeFormVisited?: string
    timePatientClickedIntakeLink?: string
    countDobAuthenticationRequests?: string
    patientSiteShowCallButton?: boolean
    patientSiteShowIntakeForm?: boolean
    patientSiteShowLocation?: boolean
    showCheckInButtonInVwr?: boolean
    showSuggestedArrivalTimeInVwr?: boolean
    translationContents?: TranslationContentFragment[]
    translatedTranslationContents?: TranslationContentFragment[]
    patientSiteContactTranslationId?: string
    patientSiteDirectionsTranslationId?: string
    patientSiteRemoteDirectionsTranslationId?: string
    patientSiteFaqTranslationId?: string
    patientSiteIntakeFormUrlTranslationId?: string
    googlePlaceId?: string
    googlePlaceData?: any
    googlePlacePhoneNumber?: string
    deptAddress?: string
    deptPhoneNumber?: string
    patientSiteDisplayPaymentPortal?: boolean
    patientSiteDisplayPatientPortal?: boolean
    patientSiteDisplayLatestSmsSuggestedArrivalTime?: boolean
    patientSitePracticeSlugId?: number
    timeLatestTextedSuggestedArrivalTime?: string
    remote?: boolean
    patientSiteAboutVideoLinkTranslationId?: string
    currentChatChannelId: number
    patientActorId: number
}


export function getIntakeStartDelay(
    appointment: DelayTimestampsAppointment,
    useEstIntakeStart = false
): Duration | null {
    const { calculatedAppointmentStatus } = appointment
    const hasStarted = !includes(
        NotStartedAppointmentStatuses,
        calculatedAppointmentStatus
    )

    return makeDurationFromStartEndTimes(
        appointment?.timeScheduled,
        first(
            orderBy(
                compact([
                    appointment?._timeIntakeStart,
                    appointment?._timeIntakeEnd,
                    appointment?._timeExamStart,
                    (useEstIntakeStart && !hasStarted)
                        ? appointment?.appointmentEstimate?.estIntakeTime
                        : null,
                ])
            )
        ),
        !hasStarted // fall back to using moment() as end time when appointment hasn't yet started
    )
}


export function isActualDelayLessThanPredictedDelay(
    appointment: DelayTimestampsAppointment,
): boolean {
    const withoutEst = getIntakeStartDelay(appointment, false)?.asMinutes()
    const withEst = getIntakeStartDelay(appointment, true)?.asMinutes()
    return !!withoutEst && !!withEst && withoutEst < withEst
}

export interface AppointmentDurationsNumber {
    checkIn: number | null
    waitForIntake: number | null
    intake: number | null
    waitForExam: number | null
    exam: number | null
    waitForCheckout: number | null
    checkout: number | null
}


export function makeProviderDepartmentCounts(
    nodes: any[]
) {
    return reduce(
        nodes,
        (mem, { providerId, departmentId, count }) => {
            mem[providerId ?? ''] = {
                ...mem?.[providerId ?? ''],
                total:
                    toNumber(count) + (mem?.[providerId ?? '']?.['total'] ?? 0),
                [departmentId ?? '']:
                    toNumber(count) +
                    (mem?.[providerId ?? '']?.[departmentId ?? ''] ?? 0),
            }
            return mem
        },
        {}
    )
}

export function showEstStartTime(
    appointment: DelayTimestampsAppointment | AppointmentDetailFragment
): boolean {
    const { appointmentEstimate } = appointment
    return (
        appointmentEstimate?.estIntakeTime &&
        !appointment?.pausedAt &&
        !isDelayCalcDisabled(appointment)
    )
}

export function isDelayCalcDisabled(
    appointment: DelayTimestampsAppointment | AppointmentDetailFragment
): boolean {
    return !!appointment?.providerDepartmentOptionCompiled?.appointmentsDisableDelayCalc
}

export enum SelectedAppointmentAction {
    CLOSED = 'CLOSED',
    SMS = 'SMS',
    ROOM_READY = 'ROOM_READY',
    APPOINTMENT_DONE = 'APPOINTMENT_DONE',
    APPOINTMENT_DETAIL = 'APPOINTMENT_DETAIL',
    MULTI = 'MULTI',
    MULTI_MESSAGE = 'MULTI_MESSAGE',
}

export function sortAppointmentsByScheduledStart(
    appointments: AppointmentOverviewFragment[] | null
): AppointmentOverviewFragment[] {
    return sortBy(
        appointments,
        (a) => (a?.timeScheduled ? moment(a?.timeScheduled)?.unix() : null),
        (a) => (a?.timeCreated ? moment(a?.timeCreated)?.unix() : null),
        'appointmentId'
    )
}

export function generateDisplayTimeDone(
    a: DelayTimestampsAppointment | AppointmentDetailFragment
): Moment | null {
    const key = getEarliestAppointmentEndTimeKey(a)
    return key ? moment(a?.[key]) : null
}

export function rankSortDoneAppointment(
    appointment: DelayTimestampsAppointment | AppointmentDetailFragment
): number | null {
    const generatedTimeDone = generateDisplayTimeDone(appointment)
    return generatedTimeDone ? moment(generatedTimeDone)?.unix() : null
}

export function sortAppointmentsByCurrentState(
    appointments: AppointmentDetailFragment[] | null
): AppointmentDetailFragment[] {
    return reverse(
        orderBy(
            compact(appointments),
            [
                (a) => {
                    const index =
                        appointmentToCalculatedAppointmentStatusIndex(a)
                    if (
                        compareAppointmentStatus(
                            a.calculatedAppointmentStatus ??
                                CalculatedAppointmentStatus.Unknown,
                            CalculatedAppointmentStatus.WaitForIntake
                        ) <= 0
                    ) {
                        // group all pending/checkin/waiting for intake appointments together
                        return 0
                    }
                    return index
                },
                (a) => {
                    if (isAppointmentInCompletedState(a))
                        return rankSortDoneAppointment(a)
                    const firstTime = first(
                        compact([
                            a._timeIntakeStart,
                            isDelayCalcDisabled(a)
                                ? null
                                : a?.appointmentEstimate?.estIntakeTime,
                            a?.timeScheduled
                        ])
                    )
                    return firstTime ? moment(firstTime)?.unix() : null
                }, // estimated intake start time,
                (a) => {
                    if (isAppointmentInCompletedState(a))
                        return rankSortDoneAppointment(a)
                    const firstTime = first(
                        compact([
                            isDelayCalcDisabled(a)
                                ? null
                                : a?.appointmentEstimate?.estIntakeTime,
                            a?.timeScheduled
                        ])
                    )
                    return firstTime ? moment(firstTime)?.unix() : null
                },
                (a) => {
                    if (isAppointmentInCompletedState(a))
                        return rankSortDoneAppointment(a)
                    return a?.timeScheduled
                        ? moment(a.timeScheduled)?.unix()
                        : null
                },
                (a) => {
                    return a?.timeScheduled
                        ? moment(a.timeScheduled)?.unix()
                        : null
                },
                (a) => appointmentToCalculatedAppointmentStatusIndex(a), // sort again by status - if two appointments have the same start time but one appointment is checking in / checked in, that appointment should bubble to the top
                (a) => moment(a.timeCreated)?.unix(), // force newer appointments to sort after existing appointments with the same start time
                'appointmentId',
            ],
            ['asc', 'desc', 'desc', 'desc', 'desc']
        )
    )
}

export function getNearestTimestamp(
    timestamps: number[],
    momentToApproximate: Moment
): number | null {
    if (
        timestamps?.length === 0 ||
        !momentToApproximate ||
        !momentToApproximate.isValid()
    )
        return null
    const unixToApproximate = momentToApproximate?.unix()
    return timestamps.reduce(function (prev, curr) {
        const thisAbs = Math.abs(curr - unixToApproximate)
        if (thisAbs > 60 * 10) return prev // Do not return anything that's >10m out of range
        return Math.abs(curr - unixToApproximate) <
            Math.abs(prev - unixToApproximate)
            ? curr
            : prev
    })
}

export function didAppointmentAppointmentTextFail(
    appointment: AppointmentOverviewFragment
): boolean {
    const appointmentText =
        appointment?.appointmentTexts?.nodes?.[0] || undefined
    return !appointmentText?.twilioResponse
        ? false
        : appointmentText.twilioResponse?.SmsStatus[0] === 'undelivered'
        ? true
        : false
}

interface PatientPortalLinkModifiers {
    athenaPracticeId?: string
}
export function getAppointmentPatientPortalLink(
    srcType: AppointmentOverviewFragment['srcType'] | undefined,
    { athenaPracticeId }: PatientPortalLinkModifiers
): string | null {
    switch(srcType){
        case(EHRSystem.ATHENA):
            return `https://${athenaPracticeId}.portal.athenahealth.com`
        case(EHRSystem.UNKNOWN):
            return 'https://docpace.com'
        default: 
            return null
    }
}

interface PaymentPortalLinkModifiers {}
export function getAppointmentPaymentPortalLink(
    srcType: AppointmentOverviewFragment['srcType'] | undefined,
    opts: PaymentPortalLinkModifiers
): string | null {
    switch(srcType){
        case(EHRSystem.ATHENA):
            return 'https://payment.patient.athenahealth.com/statement'
        case(EHRSystem.UNKNOWN):
            return 'https://docpace.com'
        default: 
            return null
    }
}

export function getSentAppointmentSAT(
    appointment: AppointmentDetailFragment
): string | undefined {
    const { appointmentEstimate, providerDepartmentOptionCompiled: pdoc, timeLatestTextedSuggestedArrivalTime, timeDrsLinkSent } = appointment ?? {}
    if(appointment?.appointmentType?.remote) return undefined;
    return first(compact([
        timeLatestTextedSuggestedArrivalTime,
        (timeDrsLinkSent && pdoc?.showSuggestedArrivalTimeInVwr === true)
            ? appointmentEstimate?.suggestedArrivalTime : undefined,
    ]))
}
