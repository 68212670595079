'use client'
import React, { useState } from 'react'
import { map, sortBy, find, compact } from 'lodash'
import moment from 'moment-timezone'
import { ApolloError, ApolloQueryResult, useQuery } from '@apollo/client'
import { useAtom } from 'jotai'

import { ctxPracticeIdAtom } from '@docpace/shared-react-atoms'
import { useAdminPracticeBaseProvidersQuery, useAdminPracticeBaseQuery } from '@docpace/admin-react-apollo'

interface UsePracticeBaseProps {
    practiceId?: string
    departmentId?: string
    providerId?: string
    skip?: boolean
}

export function useAdminPracticeBase(
    props?: UsePracticeBaseProps
){
    const [fetchError, setFetchError] = useState<ApolloError | null>(null)
    const [ ctxPracticeId ] = useAtom(ctxPracticeIdAtom)
    const practiceId = props?.practiceId ?? ctxPracticeId
    const {
        loading: practiceLoading,
        data: { practice } = {},
        refetch: practiceBaseRefetch,
    } = useAdminPracticeBaseQuery({
        variables: {
            practiceId: practiceId ?? '',
            dayStart: moment().startOf('day').format('YYYY-MM-DD'),
            dayEnd: moment().startOf('day').format('YYYY-MM-DD'),
        },
        skip: !practiceId || !!fetchError || props?.skip,
        onError: setFetchError,
    })
    const {
        loading: providersLoading,
        data,
        refetch: providersBaseRefetch,
    } = useAdminPracticeBaseProvidersQuery({
        variables: {
            practiceId: practiceId ?? '',
            dayStart: moment().startOf('day').format('YYYY-MM-DD'),
            dayEnd: moment().startOf('day').format('YYYY-MM-DD'),
        },
        skip: !practiceId || !!fetchError || props?.skip,
        onError: setFetchError,
    })
    
    const rawProviders = data?.practice?.providers

    const {
        appointmentTypes: rawAppointmentTypes,
        departments: rawDepartments,
        providerDepartmentOptions,
    } = practice ?? {}

    const appointmentTypes = compact(
        sortBy(rawAppointmentTypes?.nodes ?? [], 'nameDisplay')
    )
    const departments = compact(
        sortBy(rawDepartments?.nodes ?? [], 'publicName')
    )
    const providers = compact(
        sortBy(rawProviders?.nodes ?? [], 'displayName')
    )

    return {
        practice: practice ?? null,
        practiceId: practiceId ?? null,
        appointmentTypes,
        appointmentTypeIds: map(
            appointmentTypes,
            ({ appointmentTypeId }) => appointmentTypeId
        ),
        appointmentTypeById: (appointmentTypeId) =>
            find(appointmentTypes, { appointmentTypeId }) ?? null,
        departments,
        departmentById: (departmentId) =>
            find(departments, { departmentId }) ?? null,
        providers,
        providerById: (providerId) => find(providers, { providerId }) ?? null,
        providerDepartmentOptions:
            compact(providerDepartmentOptions?.nodes) ?? [],
        // isLoading: practiceLoading || jwtLoading,
        isLoading: practiceLoading || providersLoading,
        practiceBaseRefetch: async ()=>practiceBaseRefetch().then(()=>{}),
        providersBaseRefetch: async ()=>providersBaseRefetch().then(()=>{}),
    }
}

export default useAdminPracticeBase
