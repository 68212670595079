import { atom, useAtom } from 'jotai'
import { atomWithReset } from 'jotai/utils'
import { atomEffect } from 'jotai-effect'

import { ProviderDepartmentOptionIdObject, ProviderDepartmentOptionItem } from '@docpace/shared-ts-types/base/ProviderDepartmentOption'
import { ctxPracticeIdAtom } from '.'

export const ctxSelectedPdoIds = atomWithReset<ProviderDepartmentOptionIdObject[]>([])
export const ctxPdoItem = atomWithReset<ProviderDepartmentOptionItem | null>(null)
export const contextTranslationId = atomWithReset<string|null>(null)

export const adminAtomsPracticeReset = atomEffect((get, set)=>{
    // reset everything when the practice id changes
    get(ctxPracticeIdAtom)
    set(ctxSelectedPdoIds, [])
})