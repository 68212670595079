'use client'
import React, { FC } from 'react'
import {
    Button,
    CardActions,
    CardActionsProps,
    ButtonProps,
    FormHelperText,
} from '@material-ui/core'
import { throttle, toString } from 'lodash'

interface MuiFormSubmitControlsProps extends CardActionsProps {
    // variant?: ButtonProps['variant']
    onCancel?: () => void
    onReset?: () => void
    onSubmit?: () => void
    submitError?: Error
    disableSubmitType?: boolean
    submitTitle?: string
}

export const MuiFormSubmitControls: FC<MuiFormSubmitControlsProps> = (
    props
) => {
    const {
        onCancel,
        onReset,
        onSubmit,
        submitError,
        disableSubmitType,
        submitTitle = 'Save',
        ...cardActionsProps
    } = props
    const onSubmitThrottled = onSubmit && throttle(onSubmit, 250)// if changed, also update useMuiLoadingIndicator to match
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                justifyContent: 'flex-end',
            }}
        >
            {submitError ? (
                <FormHelperText children={toString(submitError?.message)} error />
            ) : (
                <></>
            )}
            <CardActions
                {...cardActionsProps}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
                {onCancel && (
                    <Button
                        variant={'outlined'}
                        size={'small'}
                        color="secondary"
                        onClick={() => onCancel()}
                    >
                        Cancel
                    </Button>
                )}
                {onReset && (
                    <Button
                        variant={'contained'}
                        size={'small'}
                        color="secondary"
                        onClick={() => onReset()}
                    >
                        Reset
                    </Button>
                )}
                {(onSubmit || !disableSubmitType) && (
                    <Button
                        onClick={onSubmitThrottled}
                        type={!disableSubmitType ? 'submit' : null}
                        variant={'contained'}
                        color="primary"
                        size="small"
                    >
                        {submitTitle}
                    </Button>
                )}
            </CardActions>
        </div>
    )
}

export default MuiFormSubmitControls
