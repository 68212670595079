import { atom, useAtom } from 'jotai'
import { atomEffect } from 'jotai-effect'

import { SelectedAppointmentAction } from '@docpace/shared-ts-types/base/Appointment'
import { atomWithReset } from 'jotai/utils'

export const ctxPracticeIdAtom = atomWithReset<string | undefined>(undefined)
export const ctxPracticeNameAtom = atomWithReset<string>('')
// export const ctxActorId = atomWithReset<string | undefined>(undefined)
export const ctxAdminIdAtom = atomWithReset<string | undefined>(undefined)
export const ctxManagerIdAtom = atomWithReset<string | undefined>(undefined)

export const ctxFavoritedPracticeIdsAtom = atom<string[]>([])
export const ctxFavoritedProviderIdsAtom = atom<string[]>([])
export const ctxFavoritedDepartmentIdsAtom = atom<string[]>([])
export const ctxFavoritedAppointmentTypeIdsAtom = atom<string[]>([])

export const ctxSelectedPracticeIdsAtom = atom<string[]>([])
export const ctxSelectedProviderIdsAtom = atom<string[]>([])
export const ctxSelectedDepartmentIdsAtom = atom<string[]>([])
export const ctxSelectedAppointmentTypeIdsAtom = atom<string[]>([])
export const ctxSelectedAppointmentIdsAtom = atom<string[]>([])
export const ctxSelectedAppointmentActionAtom = atomWithReset<SelectedAppointmentAction>(SelectedAppointmentAction.CLOSED)
export const ctxGetOnlySelectedAppointmentIdAtom = atom((get, set)=>{
    return get(ctxSelectedAppointmentIdsAtom)?.length === 1 ? get(ctxSelectedAppointmentIdsAtom)?.[0] : null
})

export const sharedAtomsPracticeReset = atomEffect((get, set)=>{
    // reset everything when the practice id changes
    // include this in the admin and manager layouts
    const practiceId = get(ctxPracticeIdAtom)
    
    set(ctxFavoritedProviderIdsAtom, [])
    set(ctxFavoritedDepartmentIdsAtom, [])
    set(ctxFavoritedAppointmentTypeIdsAtom, [])

    set(ctxSelectedProviderIdsAtom, [])
    set(ctxSelectedDepartmentIdsAtom, [])
    set(ctxSelectedAppointmentIdsAtom, [])
    set(ctxSelectedAppointmentActionAtom, SelectedAppointmentAction.CLOSED)
})
