import React, { FC } from 'react'
import * as yup from 'yup'

import { useMuiFormStyles } from '@docpace/shared-react-hooks/useMuiFormStyles'
import {
    useReactHookForm,
    UseReactHookFormProps,
} from '@docpace/shared-react-hooks/useReactHookForm'
import {
    MuiFormAppointmentCycleStatsTypeSelect,
} from '@docpace/shared-react-components/MuiFormAppointmentCycleStatsTypeSelect'
import {
    MuiFormSubmitControls,
} from '@docpace/shared-react-components/MuiFormSubmitControls'
import {
    AppointmentCycleStatsType,
} from '@docpace/shared-ts-types/base/AppointmentCycle'

const schema = {
    statsType: yup.mixed<AppointmentCycleStatsType>()
        .oneOf(Object.values(AppointmentCycleStatsType)),
}

export interface AdminUpdateItemStatsTypeFormProps
    extends Omit<UseReactHookFormProps, 'schema'> {
    name: string
    title: string
    onCancel?: () => any
}

export const AdminUpdateItemStatsTypeForm: FC<
    AdminUpdateItemStatsTypeFormProps
> = (props) => {
    const {
        onCancel,
        name,
        title,
        ...reactHookProps
    } = props

    const { FormWrapper, formController, submitError, doSubmit } =
        useReactHookForm({
            ...reactHookProps,
            schema,
        })
    const classes = useMuiFormStyles()

    const { getValues, setValue } = formController

    return (
        <FormWrapper formComponentProps={{ autoComplete: 'off' }}>
            <div className={classes.vertical}>
                <MuiFormAppointmentCycleStatsTypeSelect
                    label={title}
                    name={name}
                />
                <MuiFormSubmitControls onCancel={onCancel} submitError={submitError} />
            </div>
        </FormWrapper>
    )
}
