import { Moment, Duration } from 'moment-timezone'
import moment from 'moment-timezone'
import {
    filter,
    sortBy,
} from 'lodash'

export function makeDurationFromStartEndTimes(
    startTime: Moment | string | null | undefined,
    endTime: Moment | string | null | undefined,
    toNow = false
): Duration | null {
    endTime = endTime ?? (toNow ? moment() : null)
    if (!startTime || !endTime) return null
    return moment.duration(moment(endTime).diff(moment(startTime)))
}

export type AppointmentTsKey =
    | '_timeCheckOutStart'
    | '_timeCheckOutEnd'
    | 'timeEncounterCheckedOut'
    | '_timeCheckInStart'
    | '_timeCheckInEnd'
    | '_timeIntakeStart'
    | '_timeIntakeEnd'
type AppointmentTsKeyVal = {
    key: AppointmentTsKey
    value: string | null
}

export const appointmentStartTimeKeys = [
    '_timeCheckInStart',
    '_timeCheckInEnd',
    '_timeIntakeStart',
    '_timeIntakeEnd',
]
export function getFirstAppointmentStartTimeKey(
    appointment: { 
        _timeCheckInStart?: string | null, 
        _timeCheckInEnd?: string | null, 
        _timeIntakeStart?: string | null,
        _timeIntakeEnd?: string | null
    }
): AppointmentTsKey | null {
    let timestamps: AppointmentTsKeyVal[] = appointmentStartTimeKeys.map(
        (key: any) => {
            return { key, value: appointment[key] }
        }
    )
    timestamps = filter(timestamps, ({ value }) => !!value)
    return timestamps?.[0]?.key ?? null
}

export const appointmentEndTimeKeys = [
    '_timeCheckOutStart',
    '_timeCheckOutEnd',
    'timeEncounterCheckedOut',
]
export function getEarliestAppointmentEndTimeKey(
    appointment: { 
        _timeCheckOutStart?: string | null, 
        _timeCheckOutEnd?: string | null, 
        timeEncounterCheckedOut?: string | null, 
    }
): AppointmentTsKey | null {
    let timestamps: AppointmentTsKeyVal[] = appointmentEndTimeKeys.map(
        (key: any) => {
            return { key, value: appointment[key] }
        }
    )
    timestamps = sortBy(
        filter(timestamps, ({ value }) => !!value),
        ({ value }) => (value ? new Date(value) : null)
    )
    return timestamps?.[0]?.key ?? null
}
